import AWS from "aws-sdk";


AWS.config.update({
    region: "us-west-2",
});
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.VUE_APP_AWS_POOL_ID,
});

const Lambda = new AWS.Lambda({
    region: "us-west-2",
    apiVersion: "2015-03-31"
});


export {
    Lambda
}