import { Lambda } from "../boot/aws";

import axios from "axios";

export async function get_cards(jid) {
	try {
		const { data } = await _doPromise(`v1/vault/${jid}`, null);
		return data;
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

export async function get_card(payload) {
	try {
		delete payload["processor"];
		const { data } = await _doPromise(`v1/vault/${payload.jid}/${payload.alias}`, null);
		return data;
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

export async function post_card(payload) {
	try {
		delete payload["processor"];
		return _doPromise(`v1/vault/${payload.jid}`, payload, "POST");
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

export async function put_card(payload) {
	try {
		delete payload["processor"];
		return _doPromise(`v1/vault/${payload.jid}/${payload.alias}`, payload, "PUT");
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

export async function delete_card(payload) {
	try {
		delete payload["processor"];
		return await _doPromise(`v1/vault/${payload.jid}/${payload.alias}`, null, "DELETE");
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

export async function get_payment(payload) {
	try {
		delete payload["processor"];
		const { data } = await _doPromise(`v1/payment/${payload.paymentId}`, null);
		return data;
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

export async function put_payment(payload) {
	try {
		delete payload["processor"];
		return _doPromise(`v1/payment/${payload.paymentId}/${payload.jid}/${payload.alias}`, null, "PUT");
	} catch (err) {
		throw new Error(err.response.data?.message ?? "Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

function _doPromise(path, payload, method = "GET") {
	try {
		if (method === "GET") return axios.get(process.env.VUE_APP_VAULT_URL + path);
		else if (method === "POST") return axios.post(process.env.VUE_APP_VAULT_URL + path, payload);
		else if (method === "PUT") return axios.put(process.env.VUE_APP_VAULT_URL + path, payload);
		else if (method === "DELETE") return axios.delete(process.env.VUE_APP_VAULT_URL + path);
	} catch (err) {
		throw new Error("Ha ocurrido un problema al ejecutar la petición, intente de nuevo.");
	}
}

// function _doPromise(function_name, payload) {
// 	return new Promise((resolve, reject) => {
// 		Lambda.invoke(
// 			{
// 				InvocationType: "RequestResponse",
// 				FunctionName: function_name,
// 				Payload: payload,
// 			},
// 			(err, data) => {
// 				if (err) reject(err.message);
// 				else if (JSON.parse(data.Payload).statusCode !== 200) reject(JSON.parse(JSON.parse(data.Payload).body).message);
// 				else resolve(JSON.parse(JSON.parse(data.Payload).body));
// 			}
// 		);
// 	});
// }
