<template>
	<modal-component :showing="deleteDialogIsOpen" :showClose="false" :backgroundClose="false" :css="{ 'background': 'darker' }">
		<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
			<div class="sm:flex sm:items-start">
				<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10">
					<ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
				</div>
				<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
					<div as="h3" class="text-lg leading-6 font-medium text-gray-900">Eliminar tarjeta</div>
					<div class="mt-2">
						<p class="text-sm text-gray-500">¿ Esta seguro que desea eliminar la tarjeta {{ cardToDelete }} ?</p>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
			<button
				type="button"
				class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
				@click="deleteCard"
			>
				Eliminar
			</button>
			<button
				type="button"
				class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
				@click="deleteDialogIsOpen = false"
				ref="cancelButtonRef"
			>
				Cancelar
			</button>
		</div>
	</modal-component>
	<nav class="bg-gray-800 p-2 mt-0 fixed w-full z-10 top-0 text-white">Total a pagar: $ {{ amount }}</nav>
	<div class="p-10 grid overflow-hidden 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 auto-rows-auto gap-2 container-size">
		<card-component :jid="jid" :payment-id="paymentId" :redirect="redirect" :is-placeholder="true" />
		<card-component
			@onCardSelect="onSelectedCard"
			v-for="(card, index) in cards"
			:onDelete="onDelete"
			v-bind:key="index"
			:processor="card.processor"
			:last_digits="card.last_digits"
			:holder_name="card.holder_name"
			:expiration="card.expiration"
			:alias="card.alias"
			:jid="jid"
			:payment-id="paymentId"
			:selected-card="selectedCard"
			:redirect="redirect"
		/>
	</div>
	<div class="grid grid-rows-1 text-white bg-gray-800 gap-2 justify-center p-1" style="height: 45px; display: flex">
		<button :disabled="paymentId === undefined" @click="goBack" v-bind:class="{ 'bsd-disabled': paymentId === undefined }" style="width: 100px" class="bg-red-600 rounded">REGRESAR</button>
		<button :disabled="!isCardSelected" @click="goPay" v-bind:class="{ 'bsd-disabled': !isCardSelected }" style="width: 100px" class="bg-blue-700 rounded">PAGAR</button>
	</div>
</template>

<script>
	import CardComponent from "@/components/CardComponent.vue";
	import ModalComponent from "@/components/ModalComponent.vue";
	import { createToast } from "mosha-vue-toastify";
	import { useStore } from "vuex";
	import { onMounted, computed, ref, watch } from "vue";
	import { useRoute } from "vue-router";
	import { ExclamationIcon } from "@heroicons/vue/outline";
	export default {
		setup(props) {
			const store = useStore();
			const query = useRoute().query;

			const cards = computed(() => store.state.card.cards);
			const jid = computed(() => store.state.card.jid);
			const amount = computed(() => store.state.card.amount);
			const processUrl = computed(() => store.state.card.url);

			const paymentId = ref(query.paymentId);
			if (paymentId.value === undefined)
				createToast(
					{ title: "Error", description: "El parámetro paymentId es obligatorio" },
					{
						type: "danger",
						position: "top-center",
						showIcon: true,
					}
				);
			const redirect = ref(query.redirect);
			const deleteDialogIsOpen = ref(false);
			const cardToDelete = ref("");
			const isCardSelected = ref(false);
			const selectedCard = ref("");

			const onDelete = async (alias) => {
				cardToDelete.value = alias;
				deleteDialogIsOpen.value = true;
			};

			const deleteCard = async () => {
				try {
					store.commit("root/showLoading");
					await store.dispatch("card/delete_card", { jid: jid.value, alias: cardToDelete.value });
					createToast(
						{ title: "Exito", description: "Tarjeta eliminada correctamente" },
						{
							type: "success",
							position: "top-center",
							showIcon: true,
							timeout: 2500,
						}
					);
					deleteDialogIsOpen.value = false;
					cardToDelete.value = "";
					await store.dispatch("card/get_cards", jid.value);
					store.commit("root/hideLoading");
				} catch (err) {
					store.commit("root/hideLoading");
					createToast(
						{ title: "Error", description: err.message },
						{
							type: "danger",
							position: "top-center",
							showIcon: true,
						}
					);
				}
			};

			const goBack = () => {
				window.location.href = redirect.value;
			};

			const onSelectedCard = (alias) => {
				selectedCard.value = alias === selectedCard.value ? "" : alias;
				isCardSelected.value = selectedCard.value !== "";
			};

			const goPay = async () => {
				try {
					store.commit("root/showLoading");

					let payload = {
						paymentId: paymentId.value,
						alias: selectedCard.value,
						jid: jid.value,
					};

					await store.dispatch("card/put_payment", payload);

					let currentRedirect = processUrl.value.replace("%1", paymentId.value);
					currentRedirect = currentRedirect.replace("%2", encodeURIComponent(redirect.value));
					window.location.href = currentRedirect;
				} catch (err) {
					store.commit("root/hideLoading");
					createToast(
						{ title: "Error", description: err.message },
						{
							type: "danger",
							position: "top-center",
							showIcon: true,
						}
					);
				}
			};

			watch(jid, async (newJid, oldJid) => {
				if (newJid !== "")
					try {
						selectedCard.value = "";
						store.commit("root/showLoading");
						await store.dispatch("card/get_cards", newJid);
						store.commit("root/hideLoading");
					} catch (err) {
						store.commit("root/hideLoading");
						createToast(
							{ title: "Error", description: err.message },
							{
								type: "danger",
								position: "top-center",
								showIcon: true,
							}
						);
					}
			});

			onMounted(async () => {
				if (paymentId.value !== undefined)
					try {
						store.commit("root/showLoading");
						store.commit("card/EMPTY_CARDS");
						await store.dispatch("card/get_payment", paymentId.value);
					} catch (err) {
						store.commit("root/hideLoading");
						createToast(
							{ title: "Error", description: err.message },
							{
								type: "danger",
								position: "top-center",
								showIcon: true,
							}
						);
					}
			});

			return { cards, onDelete, deleteDialogIsOpen, goPay, cardToDelete, deleteCard, jid, amount, paymentId, redirect, goBack, isCardSelected, onSelectedCard, selectedCard };
		},
		components: {
			CardComponent,
			ModalComponent,
			ExclamationIcon,
		},
	};
</script>

<style scoped lang="scss">
	.darker {
		background: #000000d9;
	}

	.container-size {
		max-width: 800px;
		margin: 0 auto;
	}

	.bsd-disabled {
		background-color: gray;
	}

	@media only screen and (max-width: 768px) {
		.container-size {
			max-width: 768px;
		}
	}
</style>
