<template>
	<loading :active="isLoading" :is-full-page="true" :z-index="9999" blur="0" background-color="#4c4c4cfa" :opacity="1" color="white"/>
	<router-view />
</template>

<script>
	import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
	import { useStore } from "vuex";
	import { computed } from "vue";
	export default {
		setup() {
			const store = useStore();

			const isLoading = computed(() => store.state.root.isLoading);

			return { isLoading };
		},
		components: {
			Loading,
		},
	};
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
