import {
    createStore,
    createLogger
} from 'vuex';

import card from "./card/index"
import root from "./root/index"

const debug = process.env.NODE_ENV !== "production"

export default createStore({
    modules: {
        card,
        root
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})