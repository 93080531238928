import {
    createRouter,
    createWebHistory
} from "vue-router";
import CardList from "../views/CardList.vue";

const routes = [
    // {
    //     path: "/",
    //     name: "Home",
    //     component: Home,
    // },
    {
        path: "/",
        name: "CardList",
        component: CardList,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ "../views/CardList.vue"),
    },
    {
        path: "/card",
        name: "CardView",
        component: () => import("../views/CardView.vue"),
        props: {
            jid: "",
            isEditMode: false,
            alias: "",
            paramIsEditMode: false
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;