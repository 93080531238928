export function SET_CARDS(state, cards) {
    state.cards = cards;
}

export function EMPTY_CARDS(state) {
    state.cards = [];
}

export function SET_CURRENT_CARD(state, card) {
    state.currentCard = card;
}
export function SET_JID_AND_AMOUNT(state, content) {
    state.jid = content.jid;
    state.amount = content.amount;
    state.url = content.url;
}

export function SET_EMPTY_JId(state) {
    state.jid = "";
}