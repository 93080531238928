import md5 from "js-md5";
export function encrypt_md5(string) {
	return md5(string);
}

export function format_card(string) {
	let firstSection = string.substring(0, 4);
	let secondSection = string.substring(4, 8);
	let thirdSection = string.substring(8, 12);
	let fourthSection = string.substring(12, 16);

	return `${firstSection} ${secondSection} ${thirdSection} ${fourthSection}`;
}

export function luhn_check(val) {
	let checksum = 0; // running checksum total
	let j = 1; // takes value of 1 or 2

	// Process each digit one by one starting from the last
	for (let i = val.length - 1; i >= 0; i--) {
		let calc = 0;
		// Extract the next digit and multiply by 1 or 2 on alternative digits.
		calc = Number(val.charAt(i)) * j;

		// If the result is in two digits add 1 to the checksum total
		if (calc > 9) {
			checksum = checksum + 1;
			calc = calc - 10;
		}

		// Add the units element to the checksum total
		checksum = checksum + calc;

		// Switch the value of j
		if (j == 1) {
			j = 2;
		} else {
			j = 1;
		}
	}

	//Check if it is divisible by 10 or not.
	return checksum % 10 == 0;
}
