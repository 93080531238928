import {
    createApp
} from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueTheMask from 'vue-the-mask'
import moshaToast from 'mosha-vue-toastify'
import "mosha-vue-toastify/dist/style.css"

import "./css/site.css";
import './assets/tailwind.css'

const app = createApp(App);
app.use(router)
app.use(store)
app.use(VueTheMask)
app.use(moshaToast)
app.mount("#app");