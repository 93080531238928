import * as api from "../../api/card";
import { encrypt_md5 } from "../../utils/commons";

export async function get_cards({ state, commit }, jid) {
	try {
		const result = await api.get_cards(jid);
		commit("SET_CARDS", result);
	} catch (err) {
		throw err;
	}
}

export async function get_card({ state, commit }, payload) {
	try {
		const result = await api.get_card({
			...payload,
			alias: encrypt_md5(payload.alias),
		});
		commit("SET_CURRENT_CARD", result);
	} catch (err) {
		throw err;
	}
}

export async function post_card({ state, commit }, payload) {
	try {
		await api.post_card(payload);
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export async function put_card({ state, commit }, payload) {
	try {
		await api.put_card({
			...payload,
			alias: encrypt_md5(payload.alias),
		});
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export async function delete_card({ state, commit }, payload) {
	try {
		await api.delete_card({
			...payload,
			alias: encrypt_md5(payload.alias),
		});
	} catch (err) {
		throw err;
	}
}

export async function get_payment({ state, commit }, paymentId) {
	try {
		commit("SET_EMPTY_JId");
		const result = await api.get_payment({
			"paymentId": paymentId,
		});
		commit("SET_JID_AND_AMOUNT", { ...result, jid: encrypt_md5(result.email) });
	} catch (err) {
		throw err;
	}
}

export async function put_payment({ state, commit }, payload) {
	try {
		const result = await api.put_payment(payload);
	} catch (err) {
		throw err;
	}
}
